<!--  -->
<template>
    <div class='page-mistakes-result flex-center'>
        <confirmPro ref="confirm">
            <div class="result-body">
                <div class="result-item a">
                    <!-- :class="{'win': starNum > 0, 'lose': starNum <= 0}" -->
                    <div class="result-status" :class="{'win': isWin, 'lose': !isWin}"></div>
                </div>
                <div class="result-item b flex-center">
                    <div class="b-item a">
                        <!-- <img :src="`${PARAMS.assetsUrl}/img/subject/s9.png`" class="ex-icon"> -->
                        <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c3.png`" class="ex-icon rt">
                        <span class="text-normal">答对{{correctNum}}题</span>
                    </div>
                    <div class="b-item-line">
                        <div class="line"></div>
                    </div>
                    <div class="b-item b">
                        <!-- <img :src="`${PARAMS.assetsUrl}/img/subject/s10.png`" class="ex-icon"> -->
                        <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c4.png`" class="ex-icon wr">
                        <span class="text-normal">答错{{wrongNum}}题</span>
                    </div>
                </div>
                <div class="result-item c">
                    <!-- <dia-button type="cancel" shape="square" v-for="(v, i) in funListCul" :key="i" :disabled="v.isDisabled" @click="funHand(v)">
                        <span class="text-normal">{{v.title}}</span>
                    </dia-button> -->
                    <confirmPlay class="item" v-for="(v, i) in funListCul" :key="i" :disabled="v.isDisabled" :type="v.type" @click="funHand(v)">
                        <span class="text-normal-ab btn bold">{{v.title}}</span>
                    </confirmPlay>
                </div>
            </div>
        </confirmPro>
    </div>
</template>

<script>
    import apiClass from '@/API/page/mistakes'
    export default {
        name: '',
        props: {
            resultData: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data() {
            return {
                api: new apiClass(),
                // resultData: this.$route.params.resultData,
                funList: [
                    {title: '再做一遍', display: 'restartDisplay', fun: 'restart', type: 'success'},
                    {title: '返回', fun: 'quit'},
                ],
                mistakesInfo: {}
            };
        },
        methods: {
            pageInit(){
                this.InfoLoad()
            },
            async InfoLoad(){
                let {usercode: user_code} = this
                let res = await this.api.wrongProgress({user_code})
                if(res) this.mistakesInfo = res
            },
            restart(){
                let name = 'mistakes-exercise'
                let params = {exerciseId: -1}
                let type = 'replace'
                // this._routeHand.goto({name, params, type})
                this.$emit('restart')
                this.close()
            },
            quit(){
                let name = 'mistakes-summary'
                let type = 'replace'
                // this._routeHand.goto({name, type})
                this._routeHand.back()
            },
            funHand(val){
                let {fun} = val || {}
                if(!this[fun]) return
                this[fun](val)
            },
            async open(){
                await this.pageInit()
                this.$refs['confirm'].open({
                    showClose: true,
                    confirmType: 'c8'
                })
            },
            close(){
                this.$refs['confirm'].close()
            }
        },
        created() {
        },
        components: {
        },
        computed: {
            restartDisplay(){
                return this.noteCount > 0
            },
            noteCount(){
                let {noteCount} = this.mistakesInfo || {}
                return noteCount || 0
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            funListCul(){
                let {funList: list} = this
                let data = this._callFun.funhandAct.call(this, list)
                return data
            },
            resultDataCul(){
                // let {resultData} = this
                // return this._common.routeDataCul(resultData)
                return this.resultData || {}
            },
            correctNum(){
                let {correctNum} = this.resultDataCul
                return correctNum || 0
            },
            wrongNum(){
                let {wrongNum} = this.resultDataCul
                return wrongNum || 0
            },
            isWin(){
                return this.wrongNum == 0
            },
        },
        watch: {
            $route: {
                handler(){
                    // this.resultData = this.$route.params.resultData
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-mistakes-result{
        padding-right: @app-padding-side;
        color: #333;
        .result-body{
            // padding: 27px 16px 22px;
            width: 100%;
            height: 100%;
            // background: #fff;
            border-radius: 16px;
            position: relative;
            .result-item.a{
                position: relative;
                .result-status{
                    // width: 100%;
                    // height: 100px;
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    margin: auto;
                }
                .result-status.win{
                    width: 248px;
                    height: 85px;
                    top: 29px;
                    .background-img-max(url('@{assetsUrlV2_0}/task/result/c1.png'));

                }
                .result-status.lose{
                    width: 243px;
                    height: 33px;
                    top: 57px;
                    .background-img-max(url('@{assetsUrlV2_0}/task/result/c2.png'));

                }
                .result-star-box{
                    position: absolute;
                    width: 100%;
                    top: -14px;
                }
            }
            .result-item.b{
                position: absolute;
                top: 129px;
                left: 0;
                right: 0;
                margin: 0 auto;
                .b-item.a{
                    margin-right: 25px;
                }
                .b-item.b{
                }
                .b-item{
                    display: flex;
                    align-items: center;
                }
                .ex-icon{
                    margin-right: 3px;
                }
                .ex-icon.rt{
                    width: 15px;
                    // height: 11px;
                }
                .ex-icon.wr{
                    width: 12px;
                }
                .b-item-line{
                    height: 100%;
                    padding: 3px 0px;
                    position: absolute;
                    width: 2px;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    margin: auto;
                    .line{
                        height: 100%;
                        width: 100%;
                        background: #F5F5F5;
                    }
                }
            }
            .result-item.c{
                position: absolute;
                bottom: 36px;
                left: 0px;
                width: 100%;
                .com-dia-button+ .com-dia-button{
                    margin-top: 12px;
                }
                .item+ .item{
                    margin-top: 12px;
                }
                .com-confirm-button{
                    width: 131px;
                    height: 39px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
</style>