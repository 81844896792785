<!--  -->
<template>
    <div class='page-mistakes-exercise'>
        <exercise :sgf="sgf" 
        ref="exercise"
        @gameover="gameover"
        @nextExercise="exerciseHand"
        >
            <span class="text-normal max-width" slot="title-text">
                {{pageText.t1}}：{{exerciseCount}}道
            </span>
            <div class="ex-hand-fun" slot="extend-fun">
                <!-- <dia-button :type="buttonType" shape="square" class="flex-center" @click="exerciseHand">
                    <span class="text-normal-a">{{buttonText}}</span>
                </dia-button> -->
                <div class="button-own-set flex-center cursor-pointer scale-button" @click="exerciseHand">
                    <span class="text-normal-ba">{{buttonText}}</span>
                </div>
            </div>
            <div class="ex-fun-box" slot="fun-area" v-if="answerParseOpen">
                <confirmPlay type="mis-mine" @click="answerParseChange()" v-if="!isSelectEx">
                    <span class="text-normal-ab">{{ownAnswer ? pageText.t4 : pageText.t5}}</span>
                </confirmPlay>
                <confirmPlay type="normal" @click="nextExercise">
                    <span class="text-normal-ab">下一题</span>
                </confirmPlay>
            </div>
            <div class="board-extend-box flex-center" :class="{'own': ownAnswer, 'correct': correctAnswer}" slot="board-extend" v-if="answerParseOpen && !isSelectEx">
                <span class="text-normal bold">{{ownAnswer || correctAnswer}}</span>
            </div>
        </exercise>
        <result ref="result" :resultData.sync="resultData" @restart="restart"></result>
    </div>
</template>

<script>
    import exercise from '@/components/exercise/exercise.vue'
    import apiClass from '@/API/page/mistakes'
    import result from '../components/result.vue'


    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new apiClass(),
                mistakesInfo: {},
                exerciseList: [],
                exerciseId: this.$route.params.exerciseId,
                _exercise: null,
                userAnswer: null,
                isSelectEx: false,
                userScore: [],
                correct: null,
                answerParseData: {
                    active: false,
                    answer: 0
                },
                resultData: {}
            };
        },
        methods: {
            dataInit(){
                this.userAnswer = null
                this.correct = null
                this.isSelectEx = false
                this.answerParseOff()
                // this.answerParseOn()
            },
            idReset(){
                return this.refreshPage(-1)
            },
            refreshPage(exerciseId){
                let name = 'mistakes-exercise'
                let params = {exerciseId}
                let type = 'replace'
                this._routeHand.goto({name, type, params})
                return this._common.nextTick.call(this)
            },
            async pageInit(){
                await this.idReset()
                await this.mistakesInfoInit()
                await this.exerciseListInit()
            },
            async mistakesInfoInit(){
                let {usercode: user_code} = this
                let res = await this.api.wrongProgress({user_code})
                if(res) this.mistakesInfo = res
            },
            async exerciseListInit(){
                let {usercode: user_code} = this
                let res = await this.api.exerciseList({user_code})
                if(res){
                    this.exerciseList = res
                    this.exerciseIdCheck()
                }
            },
            async exerciseIdCheck(){
                await this._common.nextTick.call(this)
                let {
                    exSelected: {exerciseId: id} = {}
                } = this
                if(id) return
                let data = this.exerciseListCul[0] || {}
                let rid = data.exerciseId
                if(!rid) return
                id = rid
                return this.refreshPage(id)
            },
            async gameover(opt){
                let {actual: userAnswer, correct, isSelectEx, isCorrect} = opt || {}
                this.userAnswer = userAnswer
                this.correct = correct
                this.isSelectEx = isSelectEx
                this.userScore.push({userAnswer, correct})
                let res = await this.exerciseUpload(isCorrect)
                if(res) this._exercise.resultMaskOpen()
                
            },
            async exerciseUpload(isCorrect){
                let {exerciseSource: exercise_source, exerciseId: exercise_id} = this.exSelected || {}
                let {usercode: user_code, userAnswer: user_answer, isWrong} = this
                let correct = isWrong ? 0 : 1
                if(isCorrect !== undefined) correct = isCorrect ? 1 : 0
                return this.api.exerciseUpload({user_code, user_answer, correct, exercise_source, exercise_id})
            },
            async nextExercise(){
                let {
                    nextExerciseData : {exerciseId} = {}
                } = this
                if(!exerciseId) return this.gameCount()
                this.exerciseListHand()
                await this.refreshPage(-1)
                await this.refreshPage(exerciseId)
            },
            exerciseListHand(){
                if(this.isWrong) return
                let {exSelected: {index} = {}} = this
                let list = this.exerciseList
                list.splice(index, 1)
                this.exerciseList = list
            },
            async gameCount(){
                let {userScore: list = []} = this
                let correctNum = 0, wrongNum = 0
                for(let i in list){
                    let {userAnswer, correct} = list[i]
                    if(userAnswer == correct) correctNum++
                    else wrongNum++
                }
                this.resultData = {correctNum, wrongNum}
                await this._common.nextTick.call(this)
                this.$refs['result'].open()
                // let params = {
                //     resultData: this._common.encodeData({correctNum, wrongNum})
                // }
                // let name = 'mistakes-result'
                // let type = 'replace'
                // this._routeHand.goto({name, type, params})
            },
            exerciseComInit(){
                let ele = this.$refs['exercise']
                if(!ele) return
                this._exercise = ele
                this.exerciseState = true
            },
            exerciseHand(){
                this._exercise.resultMaskOff()
                if(!this.answerParseDisabled) return this.answerParse()
                this.nextExercise()
            },
            answerParse(){
                this.answerParseOn()
            },
            answerParseChange(answer){
                if(answer === undefined){
                    answer = this.answerParseData.answer
                    answer++
                    answer = answer % 2
                }
                this.answerParseData.answer = answer
                if(this._exercise) this._exercise.answerParseOn(answer)
            },
            answerParseOn(){
                let answer = 0
                this.answerParseData.active = true
                this.answerParseData.answer = answer
                // if(this._exercise) this._exercise.answerParseOn()
                this.answerParseChange(answer)
            },
            answerParseOff(){
                this.answerParseData.active = false
                this.answerParseData.answer = 0
                if(this._exercise) this._exercise.answerParseOff()
            },
            answerChange(){

            },
            async restart(){
                let name = 'mistakes-exercise'
                let params = {exerciseId: -1}
                let type = 'replace'
                this._routeHand.goto({name, params, type})
                await this._common.nextTick.call(this)
                this.pageInit()
            }
        },
        created() {
            this.pageInit()
        },
        components: {
            exercise,
            result
        },
        computed: {
            exerciseListCul(){
                let {exerciseList: list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    list[i].index = i
                }
                return list
            },
            nextExerciseData(){
                let {exerciseListCul: list, exSelected: curEx = {}} = this
                let rd = null
                for(let i in list){
                    if(list[i].exerciseId == curEx.exerciseId){
                        let tem = list[Number(i) + 1]
                        rd = tem
                    }
                }
                return rd
            },
            answerParseDisabled(){
                // if(!this.isWrong || this.isSelectEx) return true
                if(!this.isWrong) return true
                return false
            },
            exerciseCount(){
                return this.exerciseListCul.length
            },
            answerParseOpen(){
                return this.answerParseData.active
            },
            ownAnswer(){
                let {answerParseData: {answer}, pageText: {t5} = {}} = this
                return answer != 0 ? t5 : null
            },
            correctAnswer(){
                let {answerParseData: {answer}, pageText: {t4} = {}} = this
                return answer == 0 ? t4 : null
            },
            isWrong(){
                let {userAnswer, correct} = this
                return userAnswer !=  correct
            },
            buttonType(){
                let type = 'confirm'
                if(!this.answerParseDisabled) type = 'default'
                return type
            },
            buttonText(){
                let text = this.pageText.t2
                if(!this.answerParseDisabled) text = this.pageText.t3
                return text
            },
            noteCount(){
                let {noteCount = 0} = this.mistakesInfo || {}
                return noteCount
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            exSelected(){
                let {exerciseListCul: list, exerciseId: id} = this
                let rd = {}
                for(let i in list){
                    if(list[i].exerciseId == id){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            sgf(){
                let {exerciseContent: sgf} = this.exSelected || {}
                return sgf
            }
        },
        watch: {
            $route: {
                handler(){
                    this.exerciseId = this.$route.params.exerciseId
                },
                deep: true,
                immediate: true
            },
            exSelected: {
                handler(){
                    this.dataInit()
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            this.exerciseComInit()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-mistakes-exercise{
        .ex-fun-box{
            .com-dia-button+ .com-dia-button{
                margin-top: 12px;
            }
            .com-confirm-button{
                width: 148px;
                height: 38px;
            }
            .com-confirm-button+ .com-confirm-button{
                margin-top: 11px;
            }
        }
        .board-extend-box{
            position: absolute;
            bottom: 24px;
            width: 34px;
            height: 85px;
            left: -31px;
            border-radius: 8px 0px 0px 8px;
            writing-mode: vertical-lr;
            span[class^='text']{
                height: auto;
            }
        }
        .board-extend-box.own{
            // background: #FFAC40;
            .background-img-max(url('@{assetsUrlV2_0}/exercise/c17_mine.png'));

        }
        .board-extend-box.correct{
            // background: #03E16E;
            .background-img-max(url('@{assetsUrlV2_0}/exercise/c17_right.png'));
            
        }
        .button-own-set{
            width: 136px;
            height: 44px;
            margin: 0px auto;
            .background-img-max(url('@{assetsUrlV2_0}/task/c21.png'));
        }
    }
    
</style>